import {WhitelabelLanguageDetector} from "../core/WhitelabelLanguageDetector";
import {ApiGeoResponse, AutocompleteResponseEvent, GenerateTextSuggestionElementData} from "../events/Events";
import {EventBus} from "../events/EventBus";

// Elements needed
const autocomplete_field_mobile_container = document.getElementById("autocomplete-mobile");
const autocomplete_modal_object = document.getElementsByClassName("autocomplete-modal");
const autocomplete_modal_node = document.querySelectorAll(".autocomplete-modal");
const autocomplete_modal_close_node = document.querySelectorAll(".autocomplete-modal .close")[0];
const autocomplete_field = document.getElementById("search-bar") as HTMLInputElement;
const autocomplete_hidden_geo_id_field = document.getElementById("hidden-geo-id") as HTMLInputElement | null;
const autocomplete_hidden_project_id_field = document.getElementById("hidden-project-id") as HTMLInputElement | null;
const autocomplete_hidden_project_name_field = document.getElementById("hidden-project-name") as HTMLInputElement | null;
const autocomplete_data_list_container = document.getElementById("matched") as HTMLElement;
const autocomplete_field_in_modal = document.getElementById("autocomplete-mobile-input") as HTMLInputElement | null;
const languagePrefix = new WhitelabelLanguageDetector().detectLanguage();
const autocompleteForm = document.getElementById("filters-form-bar") as HTMLFormElement;
const matched = document.getElementById("matched");
let children : Array<Element> = [];
const textSuggestionCssClass = "text-suggestion";
const textSuggestionTitleCssClass = "text-suggestion-title";
const focusCssClass = "focus";

function hideAutocompleteDataList() {
    if(!!autocomplete_data_list_container) {
        autocomplete_data_list_container.style.display = 'none';
    }
}

function showAutocompleteDataList() {
   if(!!autocomplete_data_list_container) {
       autocomplete_data_list_container.style.display = 'flex';
   }

    if (matched != null) {
        children = Array.from(matched.querySelectorAll(`.${textSuggestionCssClass}:not(.${textSuggestionTitleCssClass})`));
    }
}

if (!!autocompleteForm) {
    autocompleteForm.addEventListener("keypress", (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    });
}

let debounce: NodeJS.Timeout;
let ctr: AbortController | null;
hideAutocompleteDataList();
if(!!autocomplete_field){
    autocomplete_field!.addEventListener('keyup', (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === "Enter") {
        handleKeyUp(e);
        return;
    }
    if (debounce) {
        clearTimeout(debounce);
    }
    debounce = setTimeout(async () => {
        if (ctr) {
            ctr.abort();
        }
        const {httpCall, controller}: HandleChangeResponse = handleChange(e);
        ctr = controller;
        await httpCall();
    }, 500);
    });
}

addListenerEventForAutocompleteFieldMobile();
addListenerAutocompleteModalClose();
moveModalToBodyIfExist();
window.onclick = function (e) {
    // @ts-ignore
    if (!e.target!.matches('.search-results__options')) {
        hideAutocompleteDataList();
    }
}

let focusChildIndex = 0;
function handleKeyUp(event: KeyboardEvent) {
    if (children.length > 0) {
        const focusChild = children.find((child, i) => {
            if (child.classList.contains(focusCssClass)) {
                focusChildIndex = i;
                return true;
            }
            return false;
        }) as HTMLElement | null;
        if (focusChild != null) {
            if (event.key === "ArrowDown") {
                const next = children[focusChildIndex + 1];
                if (next != null && !next!.classList.contains(textSuggestionTitleCssClass)) {
                    next!.classList.add(focusCssClass);
                    focusChild.classList.remove(focusCssClass);
                }
            } else if (event.key === "ArrowUp") {
                const previous = children[focusChildIndex - 1];
                if (previous != null && !previous!.classList.contains(textSuggestionTitleCssClass)) {
                    previous!.classList.add(focusCssClass);
                    focusChild.classList.remove(focusCssClass);
                }
            } else if (event.key === "Enter") {
                focusChild.click();
            }
            focusChild.scrollIntoView({block: "nearest"});
        } else {
            children[0].classList.add(focusCssClass);
        }
    }
}

interface HandleChangeResponse {
    controller: AbortController | null;
    httpCall: () => Promise<any>;
}

// @ts-ignore
function handleChange(e): HandleChangeResponse {
    function removeAllChildNodes(parent: HTMLElement) {
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild)
        }
    }

    // @ts-ignore
    function shouldSendForm(e) {
        return e.target.closest(".search-results__options.auto") !== null;
    }

    function handleClickOption(data: GenerateTextSuggestionElementData, e: Event) {
        const {
            id,
            name,
            completeText,
            isProject,
        } = data;
        autocomplete_field.value = completeText;
        if (!isProject) {
            autocomplete_hidden_geo_id_field!.value = id;
        } else {
            if (autocomplete_hidden_project_id_field != null) {
                autocomplete_hidden_project_id_field.value = id;
            }
            if (autocomplete_hidden_project_name_field != null) {
                autocomplete_hidden_project_name_field.value = name;
            }
        }
        if (shouldSendForm(e)) {
            autocompleteForm!.submit();
        } else {
            if (autocomplete_field_in_modal != null) {
                autocomplete_field_in_modal.value = completeText;
            }
            // @ts-ignore
            removeAllChildNodes(autocomplete_data_list_container);
            handleCloseModal();
        }
    }

    const query = e.target.value
    if (!query.length || query.length < 2) {
        hideAutocompleteDataList();
        removeAllChildNodes(autocomplete_data_list_container)
        return {
            controller: null,
            httpCall: () => Promise.resolve(),
        };
    }
    const controller: AbortController = new AbortController();

    const url = `${languagePrefix}/api/geo?q=${encodeURIComponent(query)}`;
    const httpCall: Promise<any> = fetch(url, {signal: controller.signal})
        .then(async (res) => {
            const body: ApiGeoResponse[] = await res.json();
            removeAllChildNodes(autocomplete_data_list_container);
            if (body.length === 0) {
                hideAutocompleteDataList();
                return;
            }
            EventBus.getInstance().emit(new AutocompleteResponseEvent({
                body: body,
                callbacks: {
                    generateTextSuggestionElement: (html: string, data: GenerateTextSuggestionElementData) : void => {
                        const textSuggestionElement = document.createElement('div');
                        textSuggestionElement.innerHTML = html;
                        const node = textSuggestionElement.firstElementChild!;
                        node.addEventListener("click", (e) => {
                            handleClickOption(data, e);
                        });
                        autocomplete_data_list_container.appendChild(node);
                    },
                    insertAdjacentHTML: (html: string) => {
                        autocomplete_data_list_container.insertAdjacentHTML('beforeend', html);
                    },
                    showAutocompleteDataList: showAutocompleteDataList,
                },
                cssClassNames: {
                    textSuggestionCssClass: textSuggestionCssClass,
                    textSuggestionTitleCssClass: textSuggestionTitleCssClass,
                    focusCssClass: focusCssClass,
                }
            }));
        });
    return {
        controller,
        httpCall: () => httpCall,
    };
}

// @ts-ignore
function handleOpenModal() {
    autocomplete_modal_object[0].classList.add("show");
    autocomplete_field!.focus()
    document.body.style.overflow = 'hidden';
}

// @ts-ignore
function handleCloseModal() {
    if (autocomplete_modal_object != null && autocomplete_modal_object.length > 0) {
        autocomplete_modal_object[0].classList.remove("show");
        document.body.style.overflow = 'auto';
    }
}

// @ts-ignore
function moveModalToBodyIfExist() {
    if (autocomplete_modal_node != null && autocomplete_modal_node.length > 0) {
        document.body.appendChild(autocomplete_modal_node[0]);
    }
}

// @ts-ignore
function addListenerEventForAutocompleteFieldMobile() {
    if (!! autocomplete_field_mobile_container) {
        autocomplete_field_mobile_container.addEventListener('focusin', handleOpenModal)
    }
}

// @ts-ignore
function addListenerAutocompleteModalClose() {
    if (!! autocomplete_modal_close_node) {
        autocomplete_modal_close_node.addEventListener('click', handleCloseModal)
    }
}
